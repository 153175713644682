import {Component, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from "@ngrx/store";
import {ReportActions} from "../+state/report/report.actions";
import {AppActions} from "../+state/app/app.actions";
import {getUser} from "../+state/app/app.selectors";
import {AppState} from "../+state/app/app.state";
import {listReportIds} from "../+state/report/report.selectors";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {map} from "rxjs/operators";
import {Report} from "../constants";
import {ApiService} from "../api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import * as Sentry from "@sentry/angular-ivy";
import {delay} from "../helpers";


@UntilDestroy()
@Component({
  selector: 'app-item-number-modal',
  templateUrl: './item-number-modal.component.html',
  styleUrls: ['./item-number-modal.component.scss']
})
export class ItemNumberModalComponent {
  inputValue: number | null = null;
  selectedMonth: string = ''; // Letters A through J
  selectedYear: string = ''; // Last two digits of year
  months: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];
  years: string[] = [];
  showConfirmModal: boolean = false; // When the Next button is clicked
  confirmProcessing: boolean = false; // Whether Confirm button is clicked
  errFiveDigits: boolean = false;
  errDup: boolean = false;
  itemNumber: string  = '';
  loadingProgress: number = 0;
  isEditNumberModal : boolean; // this decides whether to add a new item or replace the old
  oldItemNum : string;
  user: any;

  existingReportIds: string[] = [];

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<any>,
    private apiService: ApiService,
    private appStore: Store<AppState>,
    private dialog: MatDialogRef<ItemNumberModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
               ) {

      this.store.select(listReportIds).pipe(
        untilDestroyed(this),
        ).subscribe((reportIds) => this.existingReportIds = reportIds)

      this.appStore.select(getUser).pipe(untilDestroyed(this)).subscribe(user => {
        this.user = user;
      });

      if (data != null && data['editNumberModal']) { // if editNumberModal, we want to keep track of that variable
        this.isEditNumberModal = true;
        this.oldItemNum = data.oldItemNumber;
      } else {
        this.isEditNumberModal = false;
        this.oldItemNum = "";
      };

      // Gets year suffix
      let currentYear = new Date().getFullYear();

      this.years = [];
      while(currentYear >= 2023) {
        this.years.push(String(currentYear).substr(-2));
        currentYear -= 1;
      }
      this.selectedMonth = this.months[new Date().getMonth()];
      this.selectedYear = this.years[0];


    };

  // Checks whether duplicate item number and five digits. Advances to Confirm view.
  firstCheck(): void {
    const regexFiveDigits = /^\d{5}$/; // Regex to check whether there are 5 ints
    this.itemNumber = this.selectedMonth + '-' + this.inputValue + '-' + this.selectedYear; // Builds the item number

    // Checks if the user already has a report with this item number
    // This only checks for the user. A different user could have the same item number.
    if ((this.existingReportIds || []).includes(this.itemNumber)) {
      this.errDup = true;
      return;
    }
    else {
      this.errDup = false;
    }

    // Checks that there are five digits before proceeding to confirmation
    if (this.inputValue !== null && regexFiveDigits.test(this.inputValue.toString())) {
      this.errFiveDigits = false;
      this.showConfirmModal = true;
    } else {
      this.errFiveDigits = true; // Displays error message
    }
  };

  // Resets Confirm Modal and returns to Edit screen
  editButton(): void {
    this.showConfirmModal = false;
    this.confirmProcessing = false;
    this.loadingProgress = 0;
  };

  // On 5 second delay, will save new Item Number to state and advance to Item Summary
  confirmItem(): void {
    this.confirmProcessing = true;

    // 5 second loading spinner (does not delay code)
    const totalTime = 5000; // 5 seconds in milliseconds
    const interval = 100; // Update progress every 100 milliseconds
    const step = (interval / totalTime) * 100;
    const timer = setInterval(() => {
      if (this.loadingProgress >= 100 || !this.confirmProcessing) { // Clears spinner if Edit button clicked
        clearInterval(timer); // Stop the timer when progress reaches 100%
      } else {
        this.loadingProgress += step;
      }
    }, interval);

    // 5 second delay
    const nextCode = setTimeout(async () => {
      if (this.confirmProcessing) {
        let username = 'UNKNOWN_USER';
        if (!this.user) {
          this.snackBar.open(
            `Authentication expired, please log in again.`,
            `\u00D7`,
            {
              duration: 5000,
              panelClass: ['app-notification-info']
            },
          );

          await delay(5000);
          // user was cleared, reauth
          this.apiService.logout();
          if (this.router.url !== '/login') {
            this.router.navigateByUrl('/login');
          }
          return;
        } else {
          username = this.user.username;
        }

        if (!this.isEditNumberModal) {
          this.store.dispatch(ReportActions.CREATE_REPORT({
            id: this.itemNumber,
            userId: username,
          }));

          Sentry.metrics.increment("custom.CREATE_REPORT", 1, {
            tags: {
              id: this.itemNumber,
              username: username,
            }
          });
        }
        else {
          this.store.dispatch(ReportActions.RENAME_REPORT({
            oldItemNumber: this.oldItemNum,
            newItemNumber: this.itemNumber,
          }));

          Sentry.metrics.increment("custom.RENAME_REPORT", 1, {
            tags: {
              id: this.oldItemNum,
              newId: this.itemNumber,
              username: username,
            }
          });
        }
        this.dialog.close();
        this.store.dispatch(AppActions.SELECT_REPORT({id: this.itemNumber}));
        this.router.navigate(['/item-summary']);
      } else {
        clearTimeout(nextCode); // Cancels the code if Edit button clicked
      }
    }, 5000);

  };
}
